import React from 'react';
import CustomForm from '../components/customForm';

export default function Contact() {
  return (
    <div className="min-h-screen flex justify-center items-center w-full pt-40">
      <div className="flex w-full justify-center items-center">
        <CustomForm />
      </div>
    </div>
  );
}
