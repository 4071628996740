import CountdownTimer from '../components/countdownTimer';
import CustomForm from '../components/customForm';
import Gallary from '../components/gallary';
import Hero from '../components/hero';
import Reviews from '../components/reviews';
export default function Home() {
  return (
    <div className="flex flex-col min-h-screen justify-center items-center scrollbar-hide">
      <div className="pt-16 lg:pt-0">
        <Hero />
      </div>

      <div className="py-20 md:max-w-[70%]">
        <Gallary />
      </div>

      <div className="py-[10vh] md:max-w-[70%]">
        <CountdownTimer />
      </div>

      <div className=" py-20 md:max-w-[70%]">
        <Reviews />
      </div>

      <div className="py-20 w-full flex justify-center items-center">
        <CustomForm />
      </div>
    </div>
  );
}
