import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto p-6 py-[25vh]">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        At Mulroony Christmas Lights LLC, we are committed to protecting your
        privacy. This Privacy Policy explains how we collect, use, and safeguard
        your information when you visit our website and use our contact form.
      </p>

      <h2 className="text-2xl font-bold mb-2">Information We Collect</h2>
      <p className="mb-4">
        When you use the contact form on our website, you may voluntarily
        provide us with the following information:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Your name</li>
        <li>Your email address</li>
        <li>Your phone number (optional)</li>
        <li>
          Any additional information you choose to include in your message
        </li>
      </ul>

      <h2 className="text-2xl font-bold mb-2">How We Use Your Information</h2>
      <p className="mb-4">
        The information you provide through our contact form is used solely to
        respond to your inquiries. We do not use your personal information for
        any other purpose, and we do not share your information with any third
        parties unless required by law.
      </p>

      <h2 className="text-2xl font-bold mb-2">Data Retention and Deletion</h2>
      <p className="mb-4">
        We comply with applicable data protection regulations and retain your
        personal information only as long as necessary to fulfill the purposes
        for which it was collected. Once your inquiry has been addressed, we
        will delete your information in accordance with our data retention
        policy and legal requirements.
      </p>

      <h2 className="text-2xl font-bold mb-2">Your Rights</h2>
      <p className="mb-4">
        You have the right to request access to the personal information we hold
        about you, to request that it be corrected, or to request its deletion.
        If you have any questions about your data or wish to make a request,
        please contact us directly.
      </p>

      <h2 className="text-2xl font-bold mb-2">Updates to This Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We encourage you to review this page periodically for the latest
        information on our privacy practices.
      </p>

      <h2 className="text-2xl font-bold mb-2">Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or our
        practices, please feel free to contact us via the contact form on our
        website.
      </p>

      <p className="text-sm text-gray-600 mt-8">
        Last updated: {new Date().toLocaleDateString()}
      </p>
    </div>
  );
}
