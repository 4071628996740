import { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

interface Props {
  quote: string;
  author: string;
  review: string;
  direction?: 'left' | 'right';
}

const damping = 15;
const stiffness = 400;
const animationDuration = 1;

export default function Review(props: Props) {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '-20%', // top, right, bottom, left
  });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, isInView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{
        opacity: 0,
        x: props.direction && props.direction === 'left' ? -100 : 100,
      }}
      style={{
        opacity: 0,
        x: props.direction && props.direction === 'left' ? -100 : 100,
      }}
      transition={{
        duration: animationDuration,
        type: 'spring',
        stiffness: stiffness,
        damping: damping,
      }}
    >
      <blockquote className="flex h-full flex-col justify-between p-6 lg:p-12 gap-4 m-4 rounded-lg  bg-[#fffee1] text-left text-primary">
        <div>
          <div className="mt-4">
            <p className="text-2xl font-bold sm:text-3xl">{props.quote}</p>

            <p className="mt-4 leading-relaxed font-semibold">{props.review}</p>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between">
          <footer className="text-lg font-semibold bg-secondary rounded-lg px-1">
            {props.author}
          </footer>
        </div>
      </blockquote>
    </motion.div>
  );
}
